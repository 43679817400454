/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconOutlineTrash = ({ className }) => {
  return (
    <svg
      className={`icon-outline-trash ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 17 18"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.1041 5.41116L12.5116 13.7058C12.4606 14.4208 11.8656 14.9747 11.1489 14.9747H5.49576C4.77899 14.9747 4.18408 14.4208 4.13301 13.7058L3.54053 5.41116M6.95609 8.14361V12.2423M9.68854 8.14361V12.2423M10.3717 5.41116V3.36182C10.3717 2.98455 10.0658 2.67871 9.68854 2.67871H6.95609C6.57882 2.67871 6.27298 2.98455 6.27298 3.36182V5.41116M2.85742 5.41116H13.7872"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.36622"
      />
    </svg>
  );
};
