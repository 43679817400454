/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconSolidPlusCircle = ({ color = "#111827", className }) => {
  return (
    <svg
      className={`icon-solid-plus-circle ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M13.7831 24.9826C19.8194 24.9826 24.7129 20.0892 24.7129 14.0528C24.7129 8.01648 19.8194 3.12305 13.7831 3.12305C7.74671 3.12305 2.85327 8.01648 2.85327 14.0528C2.85327 20.0892 7.74671 24.9826 13.7831 24.9826ZM15.1493 9.95417C15.1493 9.19962 14.5376 8.58794 13.7831 8.58794C13.0285 8.58794 12.4168 9.19962 12.4168 9.95417V12.6866H9.68439C8.92985 12.6866 8.31817 13.2983 8.31817 14.0528C8.31817 14.8074 8.92985 15.4191 9.68439 15.4191H12.4168V18.1515C12.4168 18.9061 13.0285 19.5177 13.7831 19.5177C14.5376 19.5177 15.1493 18.9061 15.1493 18.1515V15.4191H17.8817C18.6363 15.4191 19.248 14.8074 19.248 14.0528C19.248 13.2983 18.6363 12.6866 17.8817 12.6866H15.1493V9.95417Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

IconSolidPlusCircle.propTypes = {
  color: PropTypes.string,
};
