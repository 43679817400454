/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconOutlineBookmark = ({ className }) => {
  return (
    <svg
      className={`icon-outline-bookmark ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 17 18"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.61279 4.18458C3.61279 3.43004 4.22447 2.81836 4.97902 2.81836H11.8101C12.5647 2.81836 13.1764 3.43004 13.1764 4.18458V15.1144L8.39458 12.7235L3.61279 15.1144V4.18458Z"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.36622"
      />
    </svg>
  );
};
