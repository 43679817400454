/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconOutlineDocumentText = ({ className }) => {
  return (
    <svg
      className={`icon-outline-document-text ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.34524 8.20367H10.4439M6.34524 10.9361H10.4439M11.8101 14.3517H4.97902C4.22447 14.3517 3.61279 13.74 3.61279 12.9855V3.42189C3.61279 2.66734 4.22447 2.05566 4.97902 2.05566H8.79474C8.97591 2.05566 9.14966 2.12763 9.27777 2.25574L12.9763 5.95426C13.1044 6.08237 13.1764 6.25612 13.1764 6.43729V12.9855C13.1764 13.74 12.5647 14.3517 11.8101 14.3517Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.36622"
      />
    </svg>
  );
};
