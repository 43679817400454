/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconOutlinePencil = ({ className }) => {
  return (
    <svg
      className={`icon-outline-pencil ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.276 4.20358L13.6912 6.61874M12.3007 3.17891C12.9676 2.51198 14.0489 2.51198 14.7158 3.17891C15.3828 3.84584 15.3828 4.92714 14.7158 5.59407L5.31094 14.999H2.92004V12.5595L12.3007 3.17891Z"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.36622"
      />
    </svg>
  );
};
