/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const IconMenuIcon = ({ iconList, iconColor, iconType, size, className }) => {
  return (
    <div className={`icon-menu-icon ${iconList} ${iconType} ${iconColor} ${className}`}>
      {iconType === "fill" && iconList === "offers" && (
        <div className="icon">
          <img
            className="subtract"
            alt="Subtract"
            src={
              iconColor === "black"
                ? "/img/subtract-2.svg"
                : iconColor === "white"
                ? "/img/subtract-1.svg"
                : iconColor === "theme"
                ? "/img/subtract.svg"
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

IconMenuIcon.propTypes = {
  iconList: PropTypes.oneOf(["calendar", "notification", "address", "offers", "refer-a-friend", "wallet", "support"]),
  iconColor: PropTypes.oneOf(["white", "black", "theme", "soft-gray", "grey"]),
  iconType: PropTypes.oneOf(["fill", "stroke"]),
  size: PropTypes.oneOf(["twenty-four"]),
};
