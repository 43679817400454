import React from "react";
import { IconMenuIcon } from "../../components/IconMenuIcon";
import { IconOutlineBookmark } from "../../icons/IconOutlineBookmark";
import { IconOutlineCollection } from "../../icons/IconOutlineCollection";
import { IconOutlineDocumentText } from "../../icons/IconOutlineDocumentText";
import { IconOutlinePencil } from "../../icons/IconOutlinePencil";
import { IconOutlineTrash } from "../../icons/IconOutlineTrash";
import { IconSolidPlusCircle } from "../../icons/IconSolidPlusCircle";
import "./style.css";
import React, { useState, useEffect } from 'react';

export const Desktop = () => {
  const [width, setwidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    const handleResize = () => {
      setwidth(document.documentElement.clientWidth);
    };

    const handleLoad = () => {
      handleResize();
      window.addEventListener('resize', handleResize);
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="desktop" style={{ transformOrigin: '0% 0%', transform: `scale(${width / 1440})` }}>
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <div className="group">
            <div className="frame">
              <div className="frame-wrapper">
                <div className="div-wrapper">
                  <div className="frame-2">
                    <div className="frame-3">
                      <div className="frame-4">
                        <div className="company-logo">
                          <div className="icons">
                            <img className="coinbase" alt="Coinbase" src="/img/coinbase-1.png" />
                          </div>
                        </div>
                        <div className="rectangle-wrapper">
                          <div className="rectangle-2" />
                        </div>
                      </div>
                    </div>
                    <img className="img" alt="Rectangle" src="/img/rectangle-86-8.svg" />
                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="frame-7">
                          <div className="text-wrapper">Full time</div>
                        </div>
                        <div className="frame-7">
                          <div className="text-wrapper">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process">
                      <div className="text-wrapper-2">100% matching</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-wrapper">
                <div className="div-wrapper">
                  <div className="frame-8">
                    <div className="frame-3">
                      <div className="frame-4">
                        <div className="company-logo">
                          <div className="icons">
                            <img className="apple" alt="Apple" src="/img/apple-1.svg" />
                          </div>
                        </div>
                        <div className="rectangle-wrapper">
                          <div className="rectangle-2" />
                        </div>
                      </div>
                    </div>
                    <img className="img" alt="Rectangle" src="/img/rectangle-86-7.svg" />
                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="frame-7">
                          <div className="text-wrapper">Full time</div>
                        </div>
                        <div className="frame-7">
                          <div className="text-wrapper">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process">
                      <div className="text-wrapper-2">100% matching</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-9">
                <div className="frame-10">
                  <div className="frame-11">
                    <div className="frame-3">
                      <div className="frame-4">
                        <div className="company-logo">
                          <div className="icons">
                            <img className="amazon" alt="Amazon" src="/img/amazon-1.png" />
                          </div>
                        </div>
                        <div className="rectangle-wrapper">
                          <div className="rectangle-2" />
                        </div>
                      </div>
                    </div>
                    <img className="img" alt="Rectangle" src="/img/rectangle-86-6.svg" />
                    <div className="frame-5">
                      <div className="frame-6">
                        <div className="frame-7">
                          <div className="text-wrapper">Full time</div>
                        </div>
                        <div className="frame-7">
                          <div className="text-wrapper">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process-2">
                      <div className="text-wrapper-3">75% matching</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="in-process-3">
            <a href="https://www.blinkle-ai.com/register">
              <div className="text-wrapper-4">Get started now</div>
            </a>
          </div>
          <div className="rectangle-3" />
          <div className="rectangle-4" />
          <div className="rectangle-5" />
          <div className="ellipse" />
          <div className="ellipse-2" />
          <div className="ellipse-3" />
          <div className="top">
            <div className="frame-12">
              <div className="avatar">
                <img className="element" alt="Element" src="/img/81.png" />
              </div>
              <div className="user">
                <div className="name">
                  <div className="text-wrapper-5">Hello</div>
                  <div className="text-wrapper-6">Job Seekers!</div>
                </div>
              </div>
            </div>
            <div className="frame-13">
              <div className="text-wrapper-7">Status</div>
              <div className="frame-14">
                <div className="ellipse-4" />
                <div className="text-wrapper-8">Actively interviewing</div>
              </div>
            </div>
            <img className="line" alt="Line" src="/img/line-3.svg" />
            <div className="frame-15">
              <div className="rectangle-6" />
            </div>
          </div>
          <div className="frame-16">
            <div className="nav-item">
              <div className="left">
                <div className="left">
                  <IconOutlineDocumentText className="icon-instance-node" />
                  <div className="text-wrapper-9">Resume</div>
                </div>
              </div>
            </div>
            <div className="left-wrapper">
              <div className="left">
                <IconMenuIcon
                  className="icon-menu-icon-instance"
                  iconColor="grey"
                  iconList="wallet"
                  iconType="stroke"
                  size="twenty-four"
                />
                <div className="text-wrapper-10">My applications</div>
              </div>
            </div>
            <div className="left-wrapper">
              <div className="left">
                <IconOutlineCollection className="icon-instance-node" />
                <div className="text-wrapper-10">Jobs for you</div>
              </div>
            </div>
            <div className="left-wrapper">
              <div className="left">
                <IconOutlineBookmark className="icon-instance-node" />
                <div className="text-wrapper-10">Saved jobs</div>
              </div>
            </div>
          </div>
          <img className="present-desk-ex" alt="Present desk ex" src="/img/blinkle-30.svg" />
          <div className="group-2">
            <div className="frame-17">
              <div className="text-wrapper-11">Resumes</div>
            </div>
            <div className="frame-18">
              <div className="frame-19">
                <div className="frame-20">
                  <div className="frame-21">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="in-process-4">
                          <div className="text-wrapper-12">70% in progress</div>
                        </div>
                        <div className="frame-24">
                          <div className="frame-25">
                            <div className="rectangle-7" />
                            <div className="rectangle-8" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-26">
                      <div className="frame-27">
                        <div className="frame-28">
                          <div className="text-wrapper-13">Full time</div>
                        </div>
                        <div className="frame-28">
                          <div className="text-wrapper-13">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="button">
                      <div className="button-content">
                        <div className="button-2">Continue</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-19">
                <div className="div-wrapper">
                  <div className="frame-29">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="in-process-5">
                          <div className="text-wrapper-14">complete</div>
                        </div>
                        <div className="frame-24">
                          <div className="frame-25">
                            <div className="rectangle-7" />
                            <div className="rectangle-8" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-30">
                      <div className="frame-31">
                        <div className="frame-28">
                          <div className="text-wrapper-13">Full time</div>
                        </div>
                        <div className="frame-28">
                          <div className="text-wrapper-13">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-32">
                      <IconOutlinePencil className="icon-instance-node" />
                      <img className="line-2" alt="Line" src="/img/line-4.svg" />
                      <IconOutlineTrash className="icon-instance-node" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-33">
                <div className="frame-34">
                  <div className="icon-solid-plus-wrapper">
                    <IconSolidPlusCircle className="icon-solid-plus" color="#424242" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-3">
            <div className="frame-35">
              <div className="text-wrapper-11">Application</div>
            </div>
            <div className="frame-36">
              <div className="frame-37">
                <div className="frame-38">
                  <div className="frame-39">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="icons-wrapper">
                          <div className="icons-2">
                            <div className="netflix">
                              <div className="overlap-group">
                                <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-1.png" />
                                <img className="rectangle-10" alt="Rectangle" src="/img/rectangle.png" />
                                <img className="vector" alt="Vector" src="/img/vector.svg" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-25">
                          <div className="rectangle-7" />
                          <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-86-5.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-40">
                      <div className="frame-41">
                        <div className="frame-42">
                          <div className="text-wrapper-15">Full time</div>
                        </div>
                        <div className="frame-42">
                          <div className="text-wrapper-15">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process-4">
                      <div className="text-wrapper-12">80% in progress</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-43">
                <div className="frame-44">
                  <div className="frame-45">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="icons-wrapper">
                          <div className="icons-2">
                            <img className="meta" alt="Meta" src="/img/meta.png" />
                          </div>
                        </div>
                        <div className="frame-25">
                          <div className="rectangle-7" />
                          <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-86-4.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-40">
                      <div className="frame-41">
                        <div className="frame-42">
                          <div className="text-wrapper-15">Full time</div>
                        </div>
                        <div className="frame-42">
                          <div className="text-wrapper-15">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process-6">
                      <div className="text-wrapper-14">applied</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-43">
                <div className="frame-44">
                  <div className="frame-45">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="icons-wrapper">
                          <div className="icons-2">
                            <img className="glovo" alt="Glovo" src="/img/glovo.svg" />
                          </div>
                        </div>
                        <div className="frame-25">
                          <div className="rectangle-7" />
                          <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-86-3.svg" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-40">
                      <div className="frame-41">
                        <div className="frame-42">
                          <div className="text-wrapper-15">Full time</div>
                        </div>
                        <div className="frame-42">
                          <div className="text-wrapper-15">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process-6">
                      <div className="text-wrapper-14">applied</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-4">
            <div className="frame-46">
              <div className="text-wrapper-11">Job for you</div>
            </div>
            <div className="frame-47">
              <div className="frame-43">
                <div className="div-wrapper">
                  <div className="frame-48">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="icons-wrapper">
                          <div className="icons-2">
                            <img className="coinbase-2" alt="Coinbase" src="/img/coinbase.png" />
                          </div>
                        </div>
                        <div className="frame-24">
                          <div className="rectangle-7" />
                        </div>
                      </div>
                    </div>
                    <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-86-2.svg" />
                    <div className="frame-40">
                      <div className="frame-41">
                        <div className="frame-42">
                          <div className="text-wrapper-15">Full time</div>
                        </div>
                        <div className="frame-42">
                          <div className="text-wrapper-15">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process-6">
                      <div className="text-wrapper-14">100% matching</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-43">
                <div className="div-wrapper">
                  <div className="frame-48">
                    <div className="frame-22">
                      <div className="frame-23">
                        <div className="icons-wrapper">
                          <div className="icons-2">
                            <img className="apple-2" alt="Apple" src="/img/apple.svg" />
                          </div>
                        </div>
                        <div className="frame-24">
                          <div className="rectangle-7" />
                        </div>
                      </div>
                    </div>
                    <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-86-1.svg" />
                    <div className="frame-40">
                      <div className="frame-41">
                        <div className="frame-42">
                          <div className="text-wrapper-15">Full time</div>
                        </div>
                        <div className="frame-42">
                          <div className="text-wrapper-15">Entry level</div>
                        </div>
                      </div>
                    </div>
                    <div className="in-process-6">
                      <div className="text-wrapper-14">100% matching</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-49">
                <div className="frame-50">
                  <div className="frame-22">
                    <div className="frame-23">
                      <div className="icons-wrapper">
                        <div className="icons-2">
                          <img className="amazon-2" alt="Amazon" src="/img/amazon.png" />
                        </div>
                      </div>
                      <div className="frame-24">
                        <div className="rectangle-7" />
                      </div>
                    </div>
                  </div>
                  <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-86.svg" />
                  <div className="frame-40">
                    <div className="frame-41">
                      <div className="frame-42">
                        <div className="text-wrapper-15">Full time</div>
                      </div>
                      <div className="frame-42">
                        <div className="text-wrapper-15">Entry level</div>
                      </div>
                    </div>
                  </div>
                  <div className="in-process-4">
                    <div className="text-wrapper-12">75% matching</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="p">Leveraging the power of AI to automate job search in a blink.</p>
          <p className="text-wrapper-16">
            In our AI-driven platform, streamline your job search with Blink. Eliminate tedious tasks and auto-customize
            resumes. Dive into precision-matched job experiences.
          </p>
          <div className="navigation-wrapper">
            <div className="navigation">
              <div className="frame-51">
                <img className="present-desk-v-ex" alt="Present desk ex" src="/img/blinkle-30.svg" />
                <div className="frame-52">

                  <div className="text-wrapper-18">About</div>
                  <div className="text-wrapper-18">Product</div>
                  <div className="text-wrapper-18">Jobs</div>
                  <div className="text-wrapper-18">Pricing</div>
                  <div className="frame-53">
                    <div className="in-process-7">
                      <a href="https://www.blinkle-ai.com/signin">
                        <div className="text-wrapper-19">Sign in</div>
                      </a>
                    </div>
                    <div className="in-process-8">
                      <a href="https://www.blinkle-ai.com/register">
                        <div className="text-wrapper-20">Get started now</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="vector-2" alt="Vector" src="/img/vector-4.svg" />
          <div className="group-5">
            <div className="text-wrapper-21">Deep AI-Driven Job Matches</div>
            <p className="text-wrapper-22">
              Dive into AI-powered job searching with Blink. We use advanced LLM machine learning to analyze your unique
              attributes and experiences, ensuring not just any match, but the perfect job tailored for you.
            </p>
          </div>
          <div className="group-6">
            <div className="AI-auto-resume">
              AI–Auto <br />
              Resume Rewrite
            </div>
            <p className="text-wrapper-23">
              No more manual edits. With Blink’s AI automation, your resume is instantly transformed, highlighting your
              strengths and ensuring you capture the attention of potential employers.
            </p>
            <div className="group-wrapper">
              <div className="group-7">
                <div className="frame-54">
                  <div className="rectangle-12" />
                  <div className="rectangle-12" />
                  <div className="rectangle-12" />
                  <div className="rectangle-12" />
                  <div className="rectangle-12" />
                  <div className="frame-55">
                    <img className="mingcute-bling-line" alt="Mingcute bling line" src="/img/mingcute-bling-line.svg" />
                    <div className="text-wrapper-24">Write in a Blink</div>
                  </div>
                  <img className="group-8" alt="Group" src="/img/group-147.png" />
                </div>
                <div className="text-wrapper-25">Job Description</div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-26">Customized Resume by JD</div>
          <p className="text-wrapper-27">
            Blink understands that every job is unique. Our platform uses AI to tailor your resume to fit individual job
            descriptions, eliminating the guesswork and enhancing your chances.
          </p>
          <div className="overlap-wrapper">
            <div className="overlap-2">
              <img className="vector-3" alt="Vector" src="/img/vector-11.svg" />
              <img className="vector-4" alt="Vector" src="/img/vector-12.svg" />
              <img className="vector-5" alt="Vector" src="/img/vector-13.svg" />
              <img className="group-9" alt="Group" src="/img/group-142.png" />
              <div className="group-10">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-34-2.svg" />
                    <div className="ellipse-6" />
                  </div>
                </div>
                <div className="rectangle-13" />
                <div className="rectangle-14" />
                <div className="rectangle-15" />
              </div>
              <div className="group-11">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-34.svg" />
                    <div className="ellipse-6" />
                  </div>
                </div>
                <div className="rectangle-13" />
                <div className="rectangle-14" />
                <div className="rectangle-15" />
              </div>
              <div className="group-12">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <img className="ellipse-5" alt="Ellipse" src="/img/ellipse-34.svg" />
                    <div className="ellipse-6" />
                  </div>
                </div>
                <div className="rectangle-13" />
                <div className="rectangle-14" />
                <div className="rectangle-15" />
              </div>
              <div className="in-process-9">
                <div className="text-wrapper-28">job 1</div>
              </div>
              <div className="in-process-10">
                <div className="text-wrapper-28">job 2</div>
              </div>
              <div className="in-process-11">
                <div className="text-wrapper-28">job 3</div>
              </div>
            </div>
          </div>
          <div className="frame-free">
            <div>
              <p className="text-1">Exclusive FREE offer for a limited time</p>
              <p className="text-2">Join us for alpha testing!</p>
            </div>
            <div className="in-process-3 fix-position">
              <a href="https://www.blinkle-ai.com/register">
                <div className="text-wrapper-4">Get started now</div>
              </a>
            </div>
          </div>
          <div className="frame-56">
            <div className="frame-57">
              <div className="frame-58">
                <div className="text-wrapper-29">Premium</div>
                <div className="text-wrapper-30"><span className="text-wrapper-line-through">$99.9</span><span className="text-wrapper-free">FREE</span></div>
              </div>
            </div>
            <div className="frame-59">
              <div className="frame-60">
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">5</span>
                    <span className="text-wrapper-31"> AI-edited resumes</span>
                  </p>
                </div>
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">30</span>
                    <span className="text-wrapper-31"> job matches/ day</span>
                  </p>
                </div>
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">30</span>
                    <span className="text-wrapper-31"> customized job application/ day</span>
                  </p>
                </div>
              </div>
              <div className="frame-62">
                <img
                  className="fluent-star-emphasis"
                  alt="Fluent star emphasis"
                  src="/img/fluent-star-emphasis-32-regular-2.svg"
                />
                <a href="https://www.blinkle-ai.com/register">
                  <div className="text-wrapper-32">Get Started</div>
                </a>
              </div>
            </div>
          </div>
          <div className="frame-63">
            <div className="frame-57">
              <div className="frame-58">
                <div className="text-wrapper-33">Standard</div>
                <div className="text-wrapper-34"><span className="text-wrapper-line-through">$79.9</span><span className="text-wrapper-free">FREE</span></div>
              </div>
            </div>
            <div className="frame-64">
              <div className="frame-60">
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">3</span>
                    <span className="text-wrapper-31"> AI-edited resumes</span>
                  </p>
                </div>
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">10</span>
                    <span className="text-wrapper-31"> job matches/ day</span>
                  </p>
                </div>
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">10</span>
                    <span className="text-wrapper-31"> customized job application/ day</span>
                  </p>
                </div>
              </div>
              <div className="frame-62">
                <img
                  className="fluent-star-emphasis"
                  alt="Fluent star emphasis"
                  src="/img/fluent-star-emphasis-32-regular-1.svg"
                />
                <a href="https://www.blinkle-ai.com/register">
                  <div className="text-wrapper-32">Get Started</div>
                </a>
              </div>
            </div>
          </div>
          <div className="frame-65">
            <div className="frame-57">
              <div className="frame-58">
                <div className="text-wrapper-29">Basic</div>
                <div className="text-wrapper-30"><span className="text-wrapper-line-through">$59.9</span><span className="text-wrapper-free">FREE</span></div>
              </div>
            </div>
            <div className="frame-66">
              <div className="frame-60">
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <div className="text-wrapper-35">2 AI-edited resumes</div>
                </div>
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="element-job-matches-day">
                    <span className="text-wrapper-36">5</span>
                    <span className="text-wrapper-37"> job matches/ day</span>
                  </p>
                </div>
                <div className="frame-61">
                  <div className="ellipse-wrapper">
                    <div className="ellipse-7" />
                  </div>
                  <p className="div-2">
                    <span className="span">5</span>
                    <span className="text-wrapper-31"> customized job application/ day</span>
                  </p>
                </div>
              </div>
              <div className="frame-62">
                <img
                  className="fluent-star-emphasis"
                  alt="Fluent star emphasis"
                  src="/img/fluent-star-emphasis-32-regular.svg"
                />
                <a href="https://www.blinkle-ai.com/register">
                  <div className="text-wrapper-32">Get Started</div>
                </a>
              </div>
            </div>
          </div>
          <img className="group-13" alt="Group" src="/img/group-9.png" />
        </div>
        <div className="rectangle-16" />
      </div>
    </div>
  );
};
