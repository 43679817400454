/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconOutlineCollection = ({ className }) => {
  return (
    <svg
      className={`icon-outline-collection ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.1764 8.02935H3.61281M13.1764 8.02935C13.9309 8.02935 14.5426 8.64103 14.5426 9.39557V13.4942C14.5426 14.2488 13.9309 14.8605 13.1764 14.8605H3.61281C2.85826 14.8605 2.24658 14.2488 2.24658 13.4942V9.39557C2.24658 8.64103 2.85826 8.02935 3.61281 8.02935M13.1764 8.02935V6.66312C13.1764 5.90858 12.5647 5.2969 11.8101 5.2969M3.61281 8.02935V6.66312C3.61281 5.90858 4.22449 5.2969 4.97903 5.2969M4.97903 5.2969V3.93068C4.97903 3.17613 5.59071 2.56445 6.34525 2.56445H10.4439C11.1985 2.56445 11.8101 3.17613 11.8101 3.93068V5.2969M4.97903 5.2969H11.8101"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.36622"
      />
    </svg>
  );
};
